import AutomatedBilling from 'src/icons/AutomatedBilling';
import MultiLocation from 'src/icons/MultiLocation';
import MultiStorefront from 'src/icons/MultiStorefront';
import { Button } from '@/components/Button';
import { Link } from '@/components/CustomLink';
import { Image } from '@/components/Image';
import { Warning } from '@/components/Warning';
import { useEntry } from '../App.hooks';
type Props = {
  className?: string;
};
export const AppDetailsSidebar = ({}: Props) => {
  const entry = useEntry();
  if ('error' in entry) {
    return <Warning>{entry.error}</Warning>;
  }
  const multiStorefront = entry.data?.multiStorefront;
  const multiLocation = entry.data?.multiLocation;
  const automatedBilling = entry.data?.automatedBilling;
  return <div className="w-full" data-sentry-component="AppDetailsSidebar" data-sentry-source-file="AppDetailsSidebar.tsx">
			{/* App Details */}
			<div className="flex flex-col mb-10 gap-4">
				<h2 className="text-bc-black font-medium text-2xl">Pricing</h2>

				{entry.data?.pricingType === 'Other' ? <div>
						<h3 className="text-bc-black text-xs font-medium">Custom Pricing: </h3>
						<p className="mb-1 text-xs">{entry.data?.pricing}</p>
					</div> : <>
						{entry.data?.freeTrial && <div className="flex gap-1">
								<h3 className="text-bc-black text-xs font-medium">Free Trial: </h3>
								<p className="mb-1 text-xs">{entry.data?.freeTrialDays} days</p>
							</div>}

						<div className="flex gap-1">
							<h3 className="text-bc-black text-xs font-medium">Upfront Fee: </h3>
							<p className="mb-1 text-xs">
								{!entry.data?.upfrontPrice ? 'Free' : ` $${entry.data?.upfrontPrice}`}
							</p>
						</div>

						{entry.data?.monthlyPrice && <div className="flex gap-1">
								<h3 className="text-bc-black text-xs font-medium">Recurring Fee:</h3>
								<p className="mb-1 text-xs">{`$${entry.data?.monthlyPrice ?? 0}`}/mo.</p>
							</div>}
					</>}
			</div>

			{entry.data?.appTier && <div className="mb-10">
					<h2 className="text-bc-black font-medium pb-4 border-b border-b-gray-1000 text-2xl">
						App Details
					</h2>

					<h3 className="text-xs text-bc-black font-medium uppercase mt-4">Partner Tier:</h3>

					<Link href={`/apps/categories/?tier=${entry.data?.appTier?.name?.toLowerCase()}`} shouldSkipTrailingSlash className="flex items-center gap-1 mt-2">
						{entry.data?.appTier?.logo?.url && <Image src={entry.data?.appTier?.logo?.url} alt={entry.data?.appTier?.name || 'App Tier'} width={entry.data.appTier?.logo?.width || 40} height={entry.data.appTier?.logo?.height || 40} className="h-6 w-min object-contain" />}

						<p className="text-bc-blue text-xs uppercase font-medium">
							{entry.data?.appTier?.name}
						</p>
					</Link>
				</div>}

			{/* Information with links */}
			<div className="mb-10 font-medium">
				<h2 className="text-bc-black pb-4 border-b border-b-gray-1000 text-2xl">Information</h2>

				<h3 className="text-bc-black text-xs uppercase mt-4 mb-1">Last Updated:</h3>

				<p className="text-gray-500 text-xs font-normal">
					{new Date(entry.data?.appRegistryUpdatedAt!).toLocaleDateString('en-us', {
          year: 'numeric',
          day: 'numeric',
          month: 'long'
        })}
				</p>

				{(multiStorefront || multiLocation || automatedBilling) && <>
						<h3 className="text-bc-black text-xs uppercase mt-4 mb-1">Compatible with:</h3>
						{/* The release date for this feature is August 16, so John asked us to keep this hidden, jira ticket attached for more context https://bigcommercecloud.atlassian.net/browse/WEBDEV-758?atlOrigin=eyJpIjoiNmNmZGU4NWZiNzQzNGVkMzgzYTFlYjU5MjJjYjYzYzMiLCJwIjoiaiJ9 */}
						{false && automatedBilling && <Link href="/apps/categories/?compatibleWith=automated-billing" shouldSkipTrailingSlash className="text-bc-blue text-xs flex items-center">
								<AutomatedBilling />
								<span className="ml-1">Automated Billing</span>
							</Link>}
						{multiStorefront && <Link href="/apps/categories/?compatibleWith=multi-storefront" shouldSkipTrailingSlash className="text-bc-blue text-xs flex items-center">
								<MultiStorefront />
								<span className="ml-1">Multi Storefront</span>
							</Link>}
						{multiLocation && <Link href="/apps/categories/?compatibleWith=multi-location" shouldSkipTrailingSlash className="text-bc-blue text-xs flex items-center">
								<MultiLocation />
								<span className="ml-1">Multi Location</span>
							</Link>}
					</>}
				{!multiStorefront && <p className="text-xs font-normal mt-4">
						This app may not be fully compatible with multi-storefront. Please get in touch with the{' '}
						{entry.data?.supportUrl ? <Link shouldSkipTrailingSlash href={entry.data?.supportUrl} className="text-bc-blue text-xs font-medium">
								technical partner
							</Link> : <span className="text-bc-blue text-xs font-medium">technical partner</span>}{' '}
						for further details.
					</p>}

				<h3 className="text-bc-black text-xs uppercase mt-4 mb-1">Documentation:</h3>

				{entry.data?.installationGuideUrl && <Link href={entry.data.installationGuideUrl} className="text-bc-blue text-xs block mb-1" dataTestId="installation-guide-btn" shouldSkipTrailingSlash>
						Installation Guide
					</Link>}

				{entry.data?.userGuideUrl && <Link href={entry.data.userGuideUrl} className="text-bc-blue text-xs block mb-1" dataTestId="user-guide-btn" shouldSkipTrailingSlash>
						User Guide
					</Link>}
			</div>

			{/* View App --> */}
			{entry.data?.supportUrl && <Button link={{
      href: entry.data?.supportUrl
    }} variant="subtle" color="primary">
					Contact Partner
				</Button>}
		</div>;
};